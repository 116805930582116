.home {
  display: flex;
  overflow: hidden;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  // background-color: #f2f3f3 !important;
  // background-image: linear-gradient(-180deg, #f1f2f2, #ffffff);
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 95%, rgba(242, 243, 243, 1) 100%) !important;
  transition: all 0.3s ease-out;
  & > section {
    width: 100% !important;
  }
  &_info_container {
    align-items: center !important;
    background-color: #f1f2f2 !important;
    border-radius: 2em !important;
    color: #64707a;
    display: flex;
    flex-direction: column !important;
    height: auto !important;
    justify-content: center !important;
    min-height: 5vh !important;
    transition: transform 0.3s ease-out;
    width: 100% !important;
  }
  &_relevant {
    align-items: center !important;
    display: flex !important;
    height: 24% !important;
    justify-content: space-around !important;
    padding: 1em !important;
  }
  &_short {
    align-items: center !important;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    justify-content: space-between !important;
    padding: 1em;
    width: 100% !important;
    &_btn {
      align-items: center;
      background-color: #fff !important;
      border-radius: 26px !important;
      box-shadow: 0px 7px 6px 0px #00000029;
      display: flex;
      height: 90px;
      justify-content: center;
      transition: all 0.5s;
      width: 90px;
      & > svg {
        color: #60269e;
        font-size: 2.4em;
      }
      &:focus {
        outline: none !important;
      }
      &:active {
        outline: none !important;
        transition: all 0.5s;
        box-shadow: 0px 1px 6px 0px #00000029;
      }
    }
    &_ttl {
      color: #64707a;
      margin-top: 0.8em !important;
    }
  }
  &_info {
    padding: 1.2em !important;
  }
  &_card {
    align-items: center;
    border-radius: 13px !important;
    display: flex;
    height: 18vh !important;
    justify-content: space-between;
    margin-bottom: 1.2em;
    width: 100%;
    & > div {
      width: 100% !important;
      height: 100% !important;
    }
    & > button {
      height: 100%;
      outline: none !important;
      width: 100%;
      &:hover {
        outline: none !important;
      }
      &:focus {
        outline: none !important;
      }
      & > div {
        width: 100% !important;
        height: auto;
      }
    }
    &_detail {
      display: flex !important;
      flex-direction: column !important;
      height: 100% !important;
      justify-content: space-around !important;
      min-width: 60% !important;
      padding: 0.8em !important;
      & > hr {
        max-width: 220px !important;
      }
      & > div {
        width: 50vw !important;
        max-width: 220px !important;
      }
      & > hr {
        width: 50vw !important;
      }
      &:hover {
        outline: none !important;
      }
      &:focus {
        outline: none !important;
      }
    }
    &_action {
      max-width: 32vw !important;
      & > div {
        height: 100% !important;
      }
    }
    &_subheader {
      align-items: center !important;
      display: flex !important;
      justify-content: flex-start !important;
      margin-top: 0.4em;
      & > div {
        height: 24px !important;
        & > span {
          padding-left: 8px !important;
          padding-right: 8px !important;
        }
      }
      & > span {
        margin-left: 0.6em !important;
      }
    }
  }
  &_empty {
    text-align: center !important;
    padding: 0.8em;
  }
}

.scrollTop_btn {
  position: fixed !important;
  bottom: 4vh;
  right: 6vw;
  width: auto; height: auto;
}
