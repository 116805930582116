.opportunity-investment-detail-header-back {
  z-index: 10;
  position: absolute;
  top: 0;
}
.opportunity-investment-detail-img-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.opportunity-investment-detail-img-bg {
  width: 80% !important;
  height: 34vh !important;
  object-fit: cover !important;
  border-radius: 0.4em !important;
}
.plus-float-button {
  position: absolute;
  bottom: -12px;
  right: calc(10% - 12px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $inverspot-red;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.opportunity-investment-detail-header-gradient {
  width: 100%;
  margin-top: 10px;
}
.opportunity-investment-detail-header-overview {
  width: 100%;
}
.detail-name {
  font-size: 25px;
  font-weight: 600;
  color: white;
}
.detail-info {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.top-border {
  border-top: 2px solid $inverspot-purple;
}
.process-item {
  background-color: #f1f2f2;
  padding: 8px;
}
.process-item-none-bg {
  padding: 8px;
}
.circle-item {
  width: 120px;
  height: 120px;
  border: 10px solid $inverspot-purple;
  border-radius: 60px;
  position: relative;
  margin-bottom: 10px;
}

.opportunity_unitprice {
  font-size: calc(16px + 6 * ((100vw - 300px) / 680));
  font-weight: 700;
}

.opportunity_title {
  font-size: 18px;
  font-weight: 500;
  max-width: 200px;
}

@media (max-width: 756px) {
  .opportunity_unitprice {
    font-size: calc(18px + 6 * ((100vw - 300px) / 680));
    font-weight: 700 !important;
  }
  .opportunity_title {
    font-size: 17px;
    font-weight: 500;
  }
}

@media (max-width: 376px) {
  .opportunity_unitprice {
    font-size: calc(16px + 6 * ((100vw - 300px) / 680)) !important;
    font-weight: 700 !important;
  }
  .opportunity_title {
    font-size: 17px;
    font-weight: 500;
  }
}
