@import 'settings/variable';  //themes' colors
@import 'mixin/font-composer.scss';

//vendor...
@import './vendor';

//base...
@import 'generic/normalize.scss';
/*@include fontFace('Roboto', 'Roboto-Regular');
@include fontFace('Roboto Light', 'Roboto-Light');
@include fontFace('Roboto Medium', 'Roboto-Medium');
@include fontFace('Roboto Bold', 'Roboto-Bold');
@include fontFace('Roboto Black', 'Roboto-Black');
@include fontFace('Segoe-UI', 'Segoe-UI');*/


@import './global/_global-dir';

@import 'generic/box-sizing.scss';
@import "base/_base-dir";
@import './ui/index';

@import "component/_index";

//pages...
@import "./containers/_index";

//objects...
@import 'objects/layout.scss';        // layout settings


