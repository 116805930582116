.contract-html-container {
  border: 1px solid;
  padding: 5px;
  overflow-y: auto;
  height: 50vh;
  border-radius: 2px;
}
.download-float-button {
  position: absolute;
  bottom: - 12px;
  right: -5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $inverspot-red;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
