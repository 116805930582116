.pos {
  &__tab-container {
    position: fixed;
    width: 100%;
    z-index: 9;
    background-color: #efefef;
  }

  &__tab {
    width: 50%;
    padding: 10px 0px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  &__tab-active {
    background-color: #e1e1e1;
  }

  &__fix-position {
    padding-top: 40px;
  }

  .nav-item {
    width: 50%;
    text-align: center;
  }
}

.react-swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.react-swipe-wrap {
  overflow: hidden;
  position: relative;
}
.react-swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}

.message__wrapper {
  position: absolute;
  top: 50%;
  left: calc(50% - 90px);
}

.message__text {
  font-size: 30px;
  font-weight: bold;
}

.customizer__btn {
  display: none;
}

.category {
  .nav-item {
    display: none !important;
  }
  .ml-auto {
    margin-left: 0px !important;
  }
}

.bottom-tabs {
  .active {
    border-bottom: solid 5px #3f51b5;
  }
}

/* Hot FIX */
.navHeight {
  button:focus{
    outline: none !important;
  }
  button:active{
    outline: none !important;
  }
}

.posMobile .grid-search-filters {
  min-width: none !important;
  flex-direction: column !important;
}

.posMobile .nav {
  margin-top: 15px;
  margin-bottom: 25px;
}

.posMobile .img_section {
  width: 100% !important;
  padding: 0 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 125px;
  }
}

.posMobile div.tab-pane:nth-child(2) {
  max-width: 85vw !important;
}

.posMobile { margin-bottom: 4.2em; }

.main_actions_container {
  height: 48px;
  background-color: #fff;
  & > button > span > span > svg {
    fill: #646f79;
  }
  & > button > span > span > span {
    background-color: #23a1db;
  }
}

.pos__center-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
}

.pos__tab-contaniner button {
  min-width: 25px !important;
}

.pos__tab-contaniner.pos__tab-type button span span {
  font-weight: bold;
  font-size: 12px;
}

.pos__tab-contaniner.pos__tab-display button {
  margin: 0px 5px;
  padding: 5px;
  min-height: 35px;
  border-radius: 10px;
}

.pos__tab-contaniner.pos__tab-display button[aria-selected="true"] {
  background-color: #3f51b5;
}

.pos__tab-contaniner.pos__tab-display button[aria-selected="true"] svg {
  color: #fff;
}

.pos__tab-contaniner.pos__tab-display span:last-child {
  display: none !important;
}

.grid__container, .table__container {
  padding: 10px;
  margin: 0px;
  height: 100%;
  max-height: 85vh;
  margin-top: 5px;

  @media screen and (max-width: 425px) {
    & {
      margin-top: 0px !important;
      max-height: 100%;
    }
  }
}

.maxWidth500px {
  max-width: 500px !important;
}

.table__container {
  height: 75vh;
}

.invitation {
  bottom: 45% !important;
  right: 10% !important;
  color: #fff !important;
  display: flex !important;
  position: fixed !important;
  max-width: 240px !important;
  flex-direction: column !important;
}

.imageview_modal {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .modal-content {
    width: 100% !important
  }
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.imageview {
  &_download {
    right: 8vw !important;
    bottom: 4vh !important;
    position: fixed !important;
    outline: none !important;
    &:hover{
      outline: none !important;
    }
    &:active {
      outline: none !important;
    }
  }
  &_stepper {
    width: 100% !important;
    align-items: center;
    justify-content: space-around;
    border-radius: 1em !important;
    margin-top: 4px !important;
  }
  &_header {
    width: 100% !important;
    min-height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &_card {
      border-radius: 1em !important;
      box-shadow: 0px 1px 8px 0px #00000000;
      background-color: #ffffff !important;
      width: 100%;
      height: 100%;
      padding: 10px !important;
      color: #2a2a2a;
      display: flex;
      align-items: center;
      & > span {
        font-size: 1.2rem !important;
      }
      & > button {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }
  &_desc {
    padding: 10px;
    align-items: center;
    bottom: 0;
    color: #fff;
    display: flex;
    height: auto;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    background: rgba(26, 25, 25, 0.49) !important;
    & > div {
      max-width: 500px !important;
    }
    & > span {
      max-width: 500px !important;
    }
    &_cont {
      width: 100%;
      max-width: 500px;
      &_card {
        padding: 10px;
        border-radius: 1em;
        background-color: #2a2a2a !important;
        box-shadow: 0px 1px 8px 0px #00000000;
        font-size: 12px !important;
      }
    }
    &_fab {
      position: fixed;
      top: 25px;
      left: 25px;
      display: flex;
      justify-content: center;
      z-index: 10;
    }
  }
  &_img {
    &_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > img {
        width: 100%;
        margin-bottom: 1em;
        border-radius: 10px !important;
      }
      & > img:last-child {
        width: 100%;
        margin-bottom: 36vw;
      }
    }
  }
  &_modal{
    & > div {
      background-color: rgba(0,0,0,0);
    }
  }
}

@media (min-width: 1025px) {
  .sub-header_container {
    width: 500px !important;
    
    & > button {
      position: unset !important;
      max-width: 350px !important;
    }
  }
  .modal-box-content {
    & > div {
      padding: 2em !important;
    }
  }
  .origin-button {
    padding: 0.8em !important;
    width: 100% !important;
    max-width: 350px !important;
    height: auto !important;
  }
  html{
    margin: 0 auto;
    overflow-y: auto !important;
   /*  max-width: 500px; */
    overflow-x: hidden !important;
    background-color: #5c2e88 !important;
  }
  .desktop_presentation {
    position: fixed !important;
    width: 500px !important;
    top: 0px !important;
    left: 50% !important;
    margin-left: -250px !important;
  }
  .sub-header {
    left: 43% !important;
    max-width: 14% !important;
  }
  #root {
    overflow-x: hidden !important;
  }
  #main-container-desktop {
    background-color: #fff !important;
    max-width: 500px !important;
  }
}

@media (min-width: 1439px) {
  .invitation {
    right: 8% !important;
  }
}

@media (max-width: 600px) {
  .MainWrapper-drawerHeader-186 {
    padding: 3.2em 3px !important;
  }
}

@media (max-width: 600px) {
  body {
    background-color: white !important;
  }
  .MuiPaper-elevation4-36 {
    box-shadow: none !important;
  }
  .box_shadow_none {
    box-shadow: none !important;
  }
  .MainWrapper-drawerHeader-186 {
    padding: 0 0.1em !important;
    min-height: 0px !important;
  }
  .MainWrapper-content-187 {
    padding: 0.05em 0 !important;
  }
  .MuiAppBar-positionFixed-22 {
    position: relative !important;
  }
  .positionRelative {
    position: relative !important;
  }
  .Product-paperRoot-210 {
    margin-top: 0.05em !important;
  }
  .product-grid {
    min-height: 75vh !important;
  }
  .posMobile > div {
    border-radius: 0px;
  }
  .Order-paperRoot-345 {
    margin-top: 0.05em !important;
  }
  .bottom-tabs {
    color: #b7bfc6;
    & > button > span > span {
      font-weight: 800 !important;
    }
    & .active {
      border-bottom: none !important;
      color: #313c52 !important;
    }
  }
}

@media (max-height: 500px) {
  .MuiBottomNavigation-root-190 {
    height: 30px !important;
  }
  .navHeight {
    height: 30px !important;
  }
}

@media (max-height: 450px) {
  .bottom-tabs {
    height: 7vh !important;
    & > button > span > span {
      display: none;
    }
  }
}

html:not([data-scroll="0"]) {
  .main_actions_container {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    button:focus{
      outline: none;
    }
    button:active{
      outline: none;
    }
  }
}
