.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0;
}

.timeline-container::after {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-left: 2px dotted #CCCCCC;
}
.timeline-item {
  margin: 20px 0 0 20px !important;
  width: 90%;
  display: block!important;
}
.timeline-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: left;
}

.timeline-container>.timeline-item:first-child .timeline-mask {
  top: -15px;
  display: block!important;

}
.timeline-container>.timeline-item:last-child .timeline-mask {
  top: calc(50% - 5px);
  display: block!important;
}
.timeline-container .timeline-mask {
  position: absolute;
  display: none;
  right: calc(100% + 16.5px);
  width: 6px;
  height: calc(50% + 10px);
  z-index: 99;
  background-color: #fff;
}
.timeline-item-content .circle {
  background-color: #fff;
  border: 2px solid #CCCCCC;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: calc(100% + 14.5px);
  width: 10px;
  height: 10px;
  z-index: 100;
  max-width: 100%;
}
.timeline-container>.timeline-item:first-child .circle {
  background-color: #64707A;
  border: none;
}
.timeline-title {
  font-size: 16px;
  font-weight: 600;
}
.timeline-description {
  font-size: 14px;
  color: grey;
  width: 90% !important;
}

.timeline_button {
  width: 48px; height: 48px;
  background-color: rgb(102, 45, 145) !important;
  border-radius: 10px !important;
  color: #fff !important;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
