.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  &.account--photo {
    /* background: url(../shared/img/404/bg_404.png) no-repeat center; */
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #ffffff;
      position: relative;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #ffffff;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }

    .account__title {
      color: #ffffff;
    }
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;
}

.account__card {
  @include themify($themes) {
    background-color: themed("colorBackground");
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-blue;
    color: #8051a0;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  margin-right: 10px;
  transition: all 0.3s;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
}

.account__head {
  margin-bottom: 30px;
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
  color: #8051a0;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.checkbox_primary {
}
.step_active {
  fill: #ffffff !important;
  & > text {
    fill: #60269e !important;
  }
}

.step_icon {
  fill: #cccccc !important;
  & > text {
    fill: #ffffff;
    font-weight: 800 !important;
  }
}

.account_panel_step {
  width: 100%;
  height: 100vh !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #60269e !important;
  & > div {
    width: 100%;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth !important;
    background-color: #60269e !important;
  }
  &_steeper {
    bottom: 0 !important;
    height: 10% !important;
    position: fixed !important;
    & > div {
      background-color: #60269e !important;
    }
  }
  &_title {
    padding: 1em;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 1.2em;
    align-items: center;
    justify-content: center;
  }
  &_children {
    margin-bottom: 10vh !important;
    background-color: #60269e !important;
  }
  &_form {
    padding: 0 8vw;
    background-color: #60269e !important;
    &_file {
      width: 100% !important;
      height: 100% !important;
      color: #ffffff !important;
      & + label {
        color: #ffffff !important;
      }
      & + button {
        background-color: #ff5600 !important;
      }
      &::-webkit-file-upload-button {
        visibility: hidden;
      }
      &::before {
        color: #fff;
        outline: none;
        cursor: pointer;
        border-radius: 2em;
        padding: 0.5em 1em;
        white-space: nowrap;
        background: #ff5600;
        display: inline-block;
        -webkit-user-select: none;
        content: "Subir Archivos";
      }
    }
  }
  &_add {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 4vh;
    & > button {
      border: none;
      display: flex;
      font-size: 17px;
      color: #ffffff;
      text-align: center;
      align-items: center;
      border-radius: 2em;
      margin-bottom: 0.6em;
      padding: 0.6em 1.6em;
      justify-content: center;
      min-height: 42px !important;
      background-color: #9e9e9e;
    }
  }
  &_submit {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
    & > button {
      border: none;
      display: flex;
      font-size: 17px;
      color: #ffffff;
      text-align: center;
      align-items: center;
      border-radius: 2em;
      margin-bottom: 0.6em;
      padding: 0.6em 1.6em;
      justify-content: center;
      min-height: 42px !important;
      background-color: #ff5600;
      &:disabled {
        background-color: #c6c6c6 !important;
      }
    }
    &_abs {
      & > button {
        width: 20vw;
        border: none;
        display: flex;
        font-size: 17px;
        color: #ffffff;
        text-align: center;
        align-items: center;
        border-radius: 2em;
        padding: 0.6em 1.6em;
        justify-content: center;
        min-height: 42px !important;
        background-color: #ff5600;
      }
      display: flex;
      justify-content: center;
      width: 100% !important;
      height: auto !important;
    }
  }
}

.select_user_type {
  &_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16vw;
    & > button {
      border: none;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      padding: 0.6em 0.9em;
      border-radius: 2em;
      background-color: $inverspot-red;
      margin-bottom: 0.6em;
    }
  }
}

.login {
  &_buttons {
    & > button, a {
      width: 100%;
      padding: 1em;
      border-radius: 2em;
      margin-bottom: 0.6em;
      padding: 0.6em 0.9em;
      color: #fff !important;
      font-size: 1.2em !important;
      font-weight: 600 !important;
      background-color: #ff5600 !important;
      &:disabled {
        background-color: #c6c6c6 !important;
      }
    }
  }
}
.btn_register {
  width: 100% !important;
  padding: 1em !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 1.2em !important;
  border-radius: 2em !important;
  margin-bottom: 0.6em !important;
  padding: 0.6em 0.9em !important;
  background-color: #ff5600 !important;
}

@media screen and (max-width: 520px) {
  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {
  .account__btns {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .login-panel-background {
    height: 100vh !important;
    overflow: hidden !important;
    overflow-y: auto !important;
    position: absolute !important;
  }
}

@media screen and (max-width: 575px) {
  .account-panel-width {
    height: auto !important;
    width: 85%;
    margin: 4em auto 6em auto !important;
    input {
      width: 100% !important;
    }
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .account-panel-width {
    width: 75%;
    margin: auto;
    input {
      width: 100% !important;
    }
  }
}
@media (min-width: 768px) {
  .account_panel_step {
    max-width: 30% !important;
    .account_panel_step_steeper {
      max-width: 30% !important;
    }
  }
  .account_panel_step_form {
    padding: 0 1vw !important;
  }
  .account-panel-width {
    width: 500px;
    margin: auto;
    input {
      width: 100% !important;
    }
  }
  .select_user_type_list {
    padding: 0 1vw !important;
  }
}
.root-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.login-panel-background {
  background: #5c2e88 !important;
}
.login-title {
}
.register-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.login-register-text {
  color: white;
  font-size: 15px;
}
.register-login-text {
  color: #5b2954;
  font-size: 15px;
}
.register-note {
  color: #fff !important;
}
.register-check-label {
  color: $inverspot-red;
}
.invalid-text {
  color: #ff5a4d !important;
}
.invalid-text-invisible {
  display: none !important;
}
