.tabs-container {
  text-align: center;
  font-size: 18px;
}

.border_bottom_purple {
  width: 50%;
  height: 3px;
  margin: 0.4em auto;
  margin-bottom: 1em !important;
  background-color:#60269E;
}
