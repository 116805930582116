.rewards {
  padding: 30px 0;

  &_title {
    font-weight: 700;
    color: #61259e;
    padding: 0 30px;
  }

  &_description {
    padding-bottom: 20px;
    font-size: 15px;
    text-align: justify;
    padding: 0 30px;
  }

  &_table {
    text-align: center;
    margin: 20px 20px 0;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    background-color: #8080800d;
  }
}

tr, th{
  height: 40px;
  width: 33.33%;
}

td, th {
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  padding: 8px;
}

th {
  font-weight: 800;
  &:nth-of-type(1) {
    border-left: 0;
  }
}

td {
  &:nth-of-type(1) {
    border-left: 0;
  }
}

thead {
  background-color: #5c2e8870;
  color: #61259e;
}
