.investment-item {
  height: 50px;
  width: 100%;
  display: flex;
}

.investment-item-container {
  border-radius: 15px;
  border: 1px solid #CCCCCC;
}

.pb-90 {
  padding-bottom: 90px !important;
}
