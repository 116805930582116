.social-item {
  width: 60px;
  height: 60px;
  background-color: $inverspot-purple;
  border-radius: 30px;
  position: relative;
  margin-bottom: 10px;
}
.copy-item {
  width: 50px;
  height: 50px;
  background-color: $inverspot-purple;
  border-radius: 15px;
  position: relative;
}
.following-link {
  height: 40px;
  border: 1px solid #DADEE2;
  background: #F1F2F2;
  border-radius: 10px;
  position: relative;
}

.following-link-text {
  color: black;
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.referral-item {
  min-height: 60px;
  height: auto !important;
  width: 100%;
  display: flex;
}
.referral-item-number-bg {
  height: 60px;
  width: 60px;
  display: table;
  text-align: center;
  background-color: #f6f6f7;
}
.referral-item-account-name {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}
.referral-item-number {
  display: table-cell;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 700;
  color: #212529;
}

.commission-icon-bg {
  height: 60px;
  width: 60px;
  display: table;
  text-align: center;
  background-color: #6c757d;
}
.commission-icon {
  display: table-cell;
  vertical-align: middle;
}
