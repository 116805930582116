.sub-header {
  background-color: $inverspot-red !important;
  color: white !important;
  text-align: center !important;
  font-size: 18px !important;
  border-radius: 25px !important;
  padding: 10px 20px !important;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  &_container {
    width: 100% !important;
    bottom: 4% !important;
    position: fixed !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
