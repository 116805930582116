.confirm-icon-bg {
  height: 60px;
  width: 60px;
  display: table;
  text-align: center;
  background-color: #ffffff;
}

.confirm-icon-bg-badge {
  height: 60px;
  width: 60px;
  display: table;
  text-align: center;
  background-color: $inverspot-red;
}
.download-icon {
  display: table-cell;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 700;
  color: $inverspot-red;
}
