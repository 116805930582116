.cart {
  & > div:focus-within {
    outline: none !important; 
  }
  & > button:focus-within {
    outline: none !important; 
  }
  button:focus {
    outline: none !important; 
  }
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15); 

  &__preview-img {
    width: 50px;
    height: 45px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    padding: 5px;
    position: absolute;
    top: 8px;

    & + span {
      padding-left: 60px;
      display: block;
      /* min-width: 400px;*/
    }

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }

  &__increase-decrease-product {
    border: none;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: #f0ebeb;
    }
  }

  &__table-btn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $color-red-hover;
    position: absolute;
    top: 0px;
    left: 0px;

    &:hover {
      color: $color-red-hover;

      svg {
        fill: $color-red-hover;
      }
    }
  }

  &__lite {
    width: 100%;
    padding: 10px 15px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
    height: 60px;
    position: relative;
  }

  &__lite-fix-card-body {
    padding: 0px;
  }

  &__lite-preview-img {
    position: relative;
    img {
      width: 40px;
      object-fit: cover;
      margin-right: 10px;
      height: 40px;
      object-position: center top;
    }
  }

  &__lite-name {
    font-weight: 500;
    max-width: 52vw;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__lite-quantity {
    position: absolute;
    top: -20px;
    left: -12px;
    background-color: #e0e0e0;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
  }

  &__lite-price {
    font-weight: bold;
    float: right;
    margin-top: 5px;
    font-size: 16px;
  }

  &__lite-delete {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #dc3545;
    color: #fff;
    border: none;
    font-size: 15px;
    font-weight: bold;
  }

  &__lite-options {
    padding: 13px 40px;
    border: none;
    vertical-align: bottom;
    margin: 0px 7px;
  }

  &__lite-input {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    width: 15%;
    height: 41px;
    text-align: center;
    vertical-align: bottom;
    border: none;
  }

  &__lite-increaseProduct {
    background-color: #d4edda;
  }

  &__lite-decreaseProduct {
    background-color: #f8d7da;
  }
}

.PaperCartList {
    padding-top: 0.5em !important;
}

.tRow-fix {
  & > td {
    text-align: center !important;
    & > input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & > input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & > input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }
}

.cart-state {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
}

.cart-state__order {
  display: inline-flex;
  align-content: center;
  align-items: center;
}

.cart-state__order > * {
  margin: 0px 5px;
}

.cart-state__order > *:first-child {
  margin-left: 0px;
}

.cart-state__order svg{
  font-size: 25px;
}

.cart-state__order span{
  font-size: 20px;
}

.cart-state__order #order-status {
  border: solid 1px #000;
  text-align: center;
}

.cart-state-__container {
  padding: 5px 10px;
  border-bottom: solid 1px #000;
}

.cart-state__email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.cart-state__emails-container {
  display: inline-flex;
  width: 100% !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
  & > div{
    margin: 5px 10px;
  }
}
