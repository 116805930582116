html{
    width: 100% !important;
}


@media (max-width: 768px) {
    html {
        width: 100% !important;
    }
  }

.login-panel {
    background: #5c2e88 !important;
    height: 500px;
}

.account-panel-width-1 {
    width: 500px;
    margin: 50px auto !important;
}

.factoraje {
    max-width: 1200px;
    margin: 100px auto 0;
    margin-bottom: 0;
    
    &__title {
        font-size: 80px;
        font-weight: 800;
        line-height: 100px;
        color: #ff5600;
        margin-bottom: 20px;
    }
    
    &__img {
        width: auto;
        height: 90%;
    }
}

.entities {
    background-color: #492779;
    color: white;

    &__container {
        max-width: 1200px;
        margin: 100px auto;

        &--title {
            font-size: 50px;
            font-weight: 800;
        }

        &--description {
            font-size: 34px;
            padding: 30px 0;
        }

        &--footer {
            font-size: 45px;
            text-align: center;
            width: 100%;
            display: block;
        }
    }
}

.work {
    max-width: 1200px;
    margin: 100px auto 0;

    &__title {
        font-size: 50px;
        font-weight: 800;
        padding: 25px 25px 50px;
        color: #492779;
    }

    .work-container{
        &__item {
            text-align: center;
            &--image-70 {
                width: 50%;
            }

            &--image-50 {
                width: 40%;
            }

            &--description {
                font-size: 20px;
                color: #86847c;
            }
        }
    }
}

.contact {
    height: 560px;
    background-color: #492779;

    &__container {
        max-width: 1200px;
        margin: 100px auto 0;
        color: white;
        padding: 65px 0px;

        &--title {
            font-size: 35px;
            text-align: center;
        }

        &--btn {
            width: 300px;
            background-color: #e7451c;
            padding: 15px 20px;
            border-radius: 50px;
            border: none;
            color: white;
            font-size: 30px;
            font-weight: 700;
            align-self: center;
        }

        &--description {
            font-size: 20px;
        }
    }
}

.address {
    height: 360px;
    max-width: 1200px;
    margin: 30px auto 0;

    &__img {
        width: 25%;
    }
    
    &__location {
        font-size: 20px;
        margin-top: 30px;
    }
}

.reader {
    color: white;
    position: fixed;
    font-size: 12px;
    bottom: 15px;
    left: 40px;
    right: 40px;
}

.reader-mobile {
    color: white;
    font-size: 12px;
    display: none;
}


@media (max-width: 768px) {
    .reader {
      display: none;
    }
    .reader-mobile {
        display: inline;
    }
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login-panel {
        height: auto;
    }

    .account-panel-width-1 {
        margin: 30px !important;
        width: auto;
    }

    .factoraje {
        flex-direction: column;
        align-items: center;

        &__title {
            font-size: 45px;
            line-height: 60px;
        }
        &__img {
            width: auto;
            max-height: 50%;
        }
    }

    .entities {
        padding: 15px;

        &__container {

            &--title {
            }

            &--description {
                font-size: 18px;
                text-align: justify;
            }

            &--footer {
                font-size: 25px;
            }
        }
    }

    .work {
        padding: 20px;
        text-align: center;
        
        .work-container{
            flex-wrap: wrap;
            &__item {
                padding: 20px;

                &--image-70 {
                    width: 50%;
                }

                &--image-50 {
                    width: 40%;
                }

                &--description {
                    font-size: 20px;
                    color: #86847c;
                }
            }
        }
    }

    .contact {
        height: auto;
    
        &__container {
            padding: 65px 20px;
            gap: 20px;
    
            &--title {
                font-size: 25px;
            }

            &--btn {
                font-size: 28px;
            }

            &--description {
                font-size: 16px;
            }
        }
    }

    .address {
        height: auto;
        margin: 30px;

        &__img {
            width: 50% !important;
        }
        
        &__location {
            font-size: 18px;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .factoraje {
        padding: 30px 20px 0 20px;
        &__title {
            font-size: 50px;
            line-height: 60px;
        }
        &__img {
            width: auto;
            height: 50%;
        }
    }

    .login-panel {
        height: auto;
    }

    .account-panel-width-1 {
        margin: 30px !important;
        width: auto;
    }


    .entities {
        padding: 15px;

        &__container {

            &--description {
                font-size: 18px;
                text-align: justify;
            }

            &--footer {
                font-size: 25px;
            }
        }
    }

    .work {
        padding: 20px;
        text-align: center;
        
        .work-container{
            flex-wrap: wrap;
            &__item {
                padding: 20px;

                &--image-70 {
                    width: 50%;
                }

                &--image-50 {
                    width: 40%;
                }

                &--description {
                    font-size: 20px;
                    color: #86847c;
                }
            }
        }
    }

    .contact {
        height: auto;
    
        &__container {
            padding: 65px 20px;
            gap: 20px;
    
            &--title {
                font-size: 25px;
            }

            &--btn {
                font-size: 28px;
            }

            &--description {
                font-size: 16px;
            }
        }
    }

    .address {
        height: auto;
        margin: 30px;

        &__img {
            width: 50% !important;
        }
        
        &__location {
            font-size: 18px;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    
    .factoraje {
        flex-direction: column;
        align-items: center;

        &__title {
            font-size: 45px;
            line-height: 60px;
        }
        &__img {
            width: auto;
            max-height: 50%;
        }
    }

    .login-panel {
        height: auto;
    }

    .account-panel-width-1 {
        margin: 30px !important;
        width: auto;
    }


    .entities {
        padding: 15px;

        &__container {

            &--title {
            }

            &--description {
                font-size: 18px;
                text-align: justify;
            }

            &--footer {
                font-size: 25px;
            }
        }
    }

    .work {
        padding: 20px;
        text-align: center;
        
        .work-container{
            flex-wrap: wrap;
            &__item {
                padding: 20px;

                &--image-70 {
                    width: 50%;
                }

                &--image-50 {
                    width: 40%;
                }

                &--description {
                    font-size: 20px;
                    color: #86847c;
                }
            }
        }
    }

    .contact {
        height: auto;
    
        &__container {
            padding: 65px 20px;
            gap: 20px;
    
            &--title {
                font-size: 25px;
            }

            &--btn {
                font-size: 28px;
            }

            &--description {
                font-size: 16px;
            }
        }
    }

    .address {
        height: auto;
        margin: 30px;

        &__img {
            width: 50%;
        }
        
        &__location {
            font-size: 18px;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .factoraje {

        &__title {
            font-size: 45px;
            line-height: 60px;
        }
        &__img {
            width: 50%;
            max-height: 50%;
        }
    }

    .entities {
        padding: 30px;

        &__container {

            &--title {
            }

            &--description {
                font-size: 20px;
                text-align: justify;
            }

            &--footer {
                font-size: 28px;
            }
        }
    }
    
    .contact {
        height: auto;
    
        &__container {
            padding: 65px 20px;
            gap: 20px;
    
            &--title {
                font-size: 25px;
            }

            &--btn {
                font-size: 28px;
            }

            &--description {
                font-size: 16px;
            }
        }
    }
    
    .address {
        height: auto;
        margin: 30px;

        &__img {
            width: 30%;
        }
        
        &__location {
            font-size: 18px;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .factoraje {
        padding: 0 30px;
        &__title {
            font-size: 45px;
            line-height: 60px;
            width: 50%;
        }
        &__img {
            width: 50%;
            max-height: 50%;
        }
    }
    .entities {
        padding: 30px;
    }
    
    .contact {
        height: auto;
    
        &__container {
            padding: 65px 20px;
            gap: 20px;
    
            &--title {
                font-size: 25px;
            }

            &--btn {
                font-size: 28px;
            }

            &--description {
                font-size: 16px;
            }
        }
    }
        
    .address {
        height: auto;
        margin: 30px;

        &__img {
            width: 30%;
        }
        
        &__location {
            font-size: 18px;
        }
    }
}
