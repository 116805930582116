.container {
    width: 100%;
    height: 100vh;
    background: white;
    padding: 0 !important;
}
.bg-avatar {
    background-color: #363c45 !important;
}
.bottom-shadow {
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.5);
}
.top-header-container {
    height: 48px;
    background-color: #fff;
    & > button > span > span > svg {
        fill: #646f79;
    }
    & > button > span > span > span {
        background-color: #23a1db;
    }
}
