.reader {
    color: white;
    position: fixed;
    font-size: 12px;
    bottom: 15px;
    left: 40px;
    right: 40px;
}

.reader-mobile {
    color: white;
    font-size: 12px;
    display: none;
}


@media (max-width: 768px) {
    .reader {
      display: none;
    }
    .reader-mobile {
        display: inline;
    }
  }


  .account-panel-width {
    margin: 0 auto !important;
}