.participation-detail-header-overview {
  width: 100%;
  height: 70% !important;
  position: absolute;
  bottom: 0;
}

.maps-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 40vh;
  width: 100% !important;
  margin-bottom: 10vh !important;
  & > iframe {
    border-radius: 0.6em;
    border: solid #c6c6c6 0.2em;
    height: 100% !important;
    width: 100% !important;
  }
}

.google-maps-link {
  display: none !important;
}