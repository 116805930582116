.searchFab {
  z-index: 4;
  &__circle {
    width: 36px;
    height: 36px;
    border-radius: 6em;
    background-color: #f1f2f2;
    overflow: hidden;
    -webkit-transition: width 0.4s;
    transition: width 0.4s;
    & > input[type="text"] {
      background-color: #f1f2f2;
      padding-left: 1em;
      width: 100%;
      min-width: 110px !important;
      height: 40px;
      border: none;
      &:focus {
        outline: none !important;
      }
    }
    & > button {
      & > span > svg {
        fill: #646f79;
      }
      padding: 6px !important;
    }
  }
  &__total {
    text-transform: uppercase;
    font-size: 1.2em;
    overflow: hidden;
    width: 0 !important;
    -webkit-transition: width 0.4s;
    transition: width 0.4s;
    height: 100% !important;
    &_price {
      font-size: 1.3em;
      font-weight: 600;
      width: auto;
      text-align: right;
      overflow: hidden;
    }
  }
  .totalExtension {
    width: 45% !important;
  }
  .extended {
    & > button > span > svg {
      fill: #313c52;
    }
    width: 100% !important;
  }
}

.cart_notEmpty {
  fill: #313c52 !important;
}

.social {
  &-menu {
    & > div {
      border-radius: 4em !important;
    }
  }
  &_paper {
    width: 45px;
    & > li {
      padding-left: 14px !important;
      padding-right: 14px !important;
    }
    & > li > a {
      font-size: 1.2em !important;
      color: $inverspot-purple !important;
      &:hover {
        color: $inverspot-light-purple !important;
      }
    }
  }
}
