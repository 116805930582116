.check-out__paper {
    padding: 25px;
    height: 100%;
}


.check-out__key-board-container {
    padding: 25px;
    height: 50vh;
}

.check-out__key-board-number{
    width: 100%;
    height: 100%;
    padding: 25px !important;
    border: solid 1px !important;
}


.check-out__money-input-container {
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    padding: 5px 0px;
}

.check-out__money-input-input {
    width: 25vw !important;
    font-size: 25px !important;
    @media screen and (max-width: 425px){
        &{
            width: 100% !important;
        }

        & input {
            text-align: center;
        }
    }
}

.check-out__item-paper {
    padding: 25px;
}

.check-out__item-scroll {
    max-height: 35vh;
    overflow-y: auto;
}