.header-bg {
  background-color: $inverspot-purple;
}

.header_progress{
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  min-height: 12vh;
  width: 100% !important;
  padding: 0 1em;
}

.header_progress_s{
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: auto !important;
  width: 100% !important;
}

.button_container {
  height: auto;
  position: absolute;
  right: 1.6em;
  bottom: -0.8em;
}

.button_container_contract {
  height: auto;
  position: absolute;
  right: 0em;
  bottom: -0.8em;
}