.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
    height: auto !important;
    min-height: 90vh !important;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 60px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}
.align-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.align-center-x {
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.align-center-y {
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.minHe50vh {
  min-height: 50vh !important;
}

.minHe70vh {
  min-height: 70vh !important;
}

.w-70 {
  width: 70% !important;
}

.pad_rigth {
  padding-right: 4em !important;
}

#main-container-desktop {
  padding: 0;
}

.block-outline {
  outline: none !important;
  &:hover {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
}

.error_form {
  border: solid 2px #ff2525;
  border-radius: 0.6em;
}
