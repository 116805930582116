.customer__is-default span {
  color: rgba(0, 0, 0, 0.54);
}

.customer__list {
  padding: 0px !important;
}

.customer__list-item {
  padding: 22px 25px !important;
}
