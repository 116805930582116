.purple-button {
  background-color: $inverspot-purple;
  border-radius: 10px;
  padding: 5px 20px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.origin-button {
  background-color: $inverspot-red !important;
  border-radius: 2em !important;
  padding: 5px 1.6em;
  text-align: center !important;
  color: white !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  height: 6vh;
  width: 70%;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: auto !important;
}

.file-button {
  background-color: $inverspot-red;
  border-radius: 5px;
  padding: 1px 10px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
