.menu {
  &_navList {
    display: flex !important;
    flex-direction: column !important;
    height: 100%;
    & > li {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    & > a {
      & > div > div {
        font-size: 16px !important;
      }
    }
  }
}

.category-bronce {
  background: linear-gradient(to right,#522514, #b8906b);
}
.category-silver {
  background: linear-gradient(to right,#807f7f, #fdfdfd);
}
.category-gold {
  background: linear-gradient(to right,#85571c, #d19140);
}
.category-platinum {
  background: linear-gradient(to right,#000000, #4d4545);
}

.resource-download {
  background-color: '#ff5600';
  color: white;
}
